import React from 'react';

import LogoInstaBoost from '../assets/images/logo-insta-boost.top.png';
import LogoHideFlow from '../assets/images/logo-hideflow.pl.png';
import LogoFlost from '../assets/images/logo-flostlab.com.png';
import LogoInstaBoostPro from '../assets/images/logo-insta-boost.pro.png';
import LogoHideflowCom from '../assets/images/logo-hideflow.com.png';

const logos = {
    'insta-boost.top': LogoInstaBoost,
    'hideflow.pl': LogoHideFlow,
    'flostlab.com': LogoFlost,
    'insta-boost.pro': LogoInstaBoostPro,
    'hideflow.com': LogoHideflowCom
};

//-----------------------|| LOGO SVG ||-----------------------//

const Logo = () => {
    // const theme = useTheme();

    return (
      /*<img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />*/
      <img src={logos[process.env.REACT_APP_NAME]} alt={`Go to home ${process.env.REACT_APP_NAME}`} width="220" />
    );
};

export default Logo;
